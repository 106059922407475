<template>
  <TopBar />
  <main class="p-2 p-md-4 overflow-x-hidden">
    <div class="container my-4">
      <div class="row g-4">
        <div class="col-12 col-xxl-6 px-xxl-5">
          <div>
            <h1 class="fs-1 fw-bold mb-3">{{ title }}</h1>
            <span class="fs-6">{{ subtitle }}</span>
            <span
              v-if="reviewsFull?.meta"
              @click="scrollToElement('reviews-section')"
            >
              <ReviewStars
                :average="reviewsFull?.meta?.average"
                :total="reviewsFull?.meta?.total"
              />
            </span>
          </div>

          <ImageCarousel :images="gallery.images" />
        </div>

        <div class="col-12 col-xxl-6 border border-2 rounded-4 shadow p-4">
          <CalendarWithTimeslots
            v-if="!isBikeRental"
            :postId="postId"
            :productId="productId"
            :price="price"
            :priceChildren="priceChildren"
            :duration="duration"
            :cityPageOfProduct="cityPageOfProduct"
            :cityPageOfProductUrl="cityPageOfProductUrl"
            :cityName="cityName"
            :productsOfCity="productsOfCity"
            :rentalProductOfCity="rentalProductOfCity"
          />

          <BikeRentalSelection
            v-else
            :productId="productId"
            :price="price"
            :priceChildren="priceChildren"
            :duration="duration"
          />
        </div>
      </div>

      <!-- Tabs Section -->
      <div class="mt-4">
        <ul class="d-flex justify-content-center fs-7 nav nav-tabs gap-1 pb-1">
          <li class="nav-item flex-fill rounded-top-3 gap-2" v-for="(tab, index) in tabs" :key="index">
            <button
              class="nav-link btn w-100"
              @click="activeTab = index; scrollToElement(`product-content-${index + 1}`)"
            >
              {{ tab.name }}
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div
            class="tab-pane fade show active"
          >
            <div id="product-content-1" class="mb-3" v-html="tabContent1"></div>
          </div>
          <div
            class="tab-pane pane-2 fade show active d-flex"
            style="flex-wrap: wrap;"
          >
            <div id="product-content-2" class="mb-3 col-xs-6" v-html="tabContent2"></div>
            <div id="product-content-3" class="mb-3 col-xs-6">
              <div v-html="tabContent3"></div>
              <div v-if="address" class="map">
                <div class="address">
                    <strong>{{ address }}</strong>
                </div>

                <iframe class="map-top" :src="mapSrc" ></iframe>

                <div class="direction">
                    <a :href="`//maps.google.com/maps?daddr=${address}`" target="_blank">{{ $t('show_route_to_accommodation') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Reviews -->
      <div
        v-if="reviewsFull?.data?.length > 0"
        id="reviews-section"
        class="text-center my-5 pt-5 pb-1 pb-md-5 "
      >
        <img src="@/assets/images/ekomi.svg" alt="Ekomi logo" class="reviews-logo">
        <div class="container mt-4">
          <h3 class="heading fw-bold mb-3">{{ $t('reviews_section_title') }}</h3>
          <span v-html="$t('reviews_section_subtitle', { clients: reviewsFull?.meta?.total, score: reviewsFull?.meta?.average.toFixed(1) })"></span>
          <div
            v-if="!isMobile"
            :class="{ 'gradient-mask': reviewsFull?.meta?.total > reviewsFull?.data?.length, 'reviews-grid': isWideScreen }"
            class="mt-5 d-grid gap-3"
          >
            <Review
              v-if="reviewsFull?.data?.length > 0"
              v-for="review in reviewsFull.data"
              :key="review.order_id"
              :review="review"
            />
          </div>
          <div v-else>
            <ItemCarousel
              :items="reviewsFull.data"
              :show-nav-buttons="false"
              :items-per-view="1"
              :draggable="true"
              :infinite-scroll="false"
              @lastItemReached="loadMoreReviews"
            >
              <template #item="{ item }">
                <Review :review="item" />
              </template>
            </ItemCarousel>
          </div>
        </div>
        <button
          v-if="reviewsFull?.meta?.total > reviewsFull?.data?.length"
          class="read-more-reviews d-none d-md-inline-block btn rounded-3 px-4 py-2 mt-5"
          @click="loadMoreReviews()"
        >{{ $t('read_more_reviews') }}</button>
      </div>

      <div
        v-if="favoriteTours?.tours?.length > 0"
        class="my-4"
      >
        <h3 v-if="favoriteTours.title" class="text-center fw-bold mb-3">{{ favoriteTours.title }}</h3>
        <h4 v-if="favoriteTours.subTitle" class="text-center">{{ favoriteTours.subTitle }}</h4>

        <TopBikeTours :items="favoriteTours.tours"/>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useEkomiReviews } from "@/composables/useEkomiReviews";
import { useLocaleStore } from "@/stores/localeStore";
import { useMobile } from "@/composables/useMobile";

import TopBar from "@/components/TopBar.vue";
import ReviewStars from "@/components/ReviewStars.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import CalendarWithTimeslots from "@/components/CalendarWithTimeslots.vue";
import BikeRentalSelection from "@/components/BikeRentalSelection.vue";
import TopBikeTours from "@/components/TopBikeTours.vue";
import Review from "@/components/Review.vue";
import type { TourData } from "@/types/top-bike-tour";
import ItemCarousel from "@/components/ItemCarousel.vue";

const props = defineProps<{
  locale: string,
  postId: number,
  productId: string,
  price: string,
  priceChildren: string,
  duration: string,
  title: string,
  subtitle: string,
  tabTitle1: string,
  tabTitle2: string,
  tabTitle3: string,
  tabContent1: string,
  tabContent2: string,
  tabContent3: string,
  address: string,
  gallery: any,
  isBikeRental: boolean,
  cityPageOfProduct: any,
  cityPageOfProductUrl: string,
  cityName: string,
  productsOfCity: any,
  rentalProductOfCity: any,
  favoriteTours: {
    title: string,
    subTitle: string,
    tours: TourData[],
  }
}>();

const localestore = useLocaleStore();
localestore.setLocale(props.locale);

const { isWideScreen, isMobile } = useMobile();

const { reviewsFull, fetchReviewsFull } = useEkomiReviews();
fetchReviewsFull(Number(props.productId), 9, 0, props.locale);

const activeTab = ref(0);
const tabs = ref([
  { name: props.tabTitle1 },
  { name: props.tabTitle2 },
  { name: props.tabTitle3 },
]);

const loadMoreReviews = () => {
  fetchReviewsFull(Number(props.productId), 6, reviewsFull.value.data.length, props.locale);
}

const scrollToElement = (targetElementId: string) => {
  const element = document.getElementById(targetElementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const mapSrc = computed(() =>
  `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_AVAILABILITIES_API_URL}&q=${props.address}&zoom=14`
);
</script>

<style scoped>
.nav-tabs {
  border-bottom: 1px solid #E8F3FA;
  .nav-item {
    background-color: #E8F3FA;
  }

  .nav-link {
    color: #2C508E;
    font-weight: 600;
  }

  .nav-link:hover {
    color: #495057;
    background-color: #fff;
  }
}

.tab-pane {
  &::v-deep(h2) {
    padding: 1rem 0;
  }

  &::v-deep(ul) {
    list-style: none;
    padding-left: 0;

    li::before {
      content: '\2713';
      margin-right: 8px;
    }
  }
}

#product-content-1 {
  &::v-deep(strong) {
    width: 100%;
  }

  &::v-deep(ul:nth-of-type(2)) {
    columns: 2;
  }

  &::v-deep(h3) {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

#product-content-2 {
  &::v-deep(i.fa-stack-2x) {
    color: #2C508E;
  }

  &::v-deep(.fa-ul) {
    li::before {
      content: '';
    }
  }
}

#product-content-3 {
  &::v-deep(i.fa-stack-2x) {
    color: #2C508E;
  }

  &::v-deep(.fa-ul) {
    li::before {
      content: '';
    }
  }
}

@media (min-width: 768px) {
  .pane-2 {
    gap: 2rem;
  }

  #product-content-2, #product-content-3 {
    flex: 1;
    width: 50%;
  }

  .heading {
    font-size: 2.5rem;
  }
}

#reviews-section {
  position: relative;
  background-color: #F3F9FD;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  .reviews-logo {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid #fff;
    border-radius: 50%;
  }
  .read-more-reviews {
    color: #252525;
    border: 1px solid #252525;

    &:hover {
      border: 1px solid #252525;
    }
  }
  .reviews-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .gradient-mask {
    mask-image: linear-gradient(0deg, transparent, #fff 150px);
  }
}
</style>