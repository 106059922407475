<template>
  <div v-if="total > 0" class="d-flex align-items-center py-3">
    <div class="me-2">
      <span v-for="star in 5" :key="star">
        <Icon
          :type="getStarClass(star).type"
          :name="getStarClass(star).class"
          customClass="star-icon"
        />
      </span>
    </div>
    <span v-if="!starsOnly">
      <div v-if="withLink" class="cursor-pointer">
        <span class="text-primary fs-7">{{ total }} reviews</span>
      </div>
      <div v-else>
        <span class="fw-bold text-secondary">{{ average.toFixed(1) }}</span>
        <span class="text-muted">({{ total }})</span>
      </div>
    </span>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  average?: number;
  total?: number;
  withLink?: boolean;
  starsOnly?: boolean;
}>(), {
  average: 0,
  total: 0,
  withLink: true,
  starsOnly: false,
});

const getStarClass = (star: number) => {
  if (props.average >= star) {
    return { class: 'fa-star', type: 'fas' };
  }
  if (props.average >= star - 0.5) {
    return { class: 'fa-star-half-stroke', type: 'far' };
  }
  return { class: 'fa-star', type: 'far' };
};
</script>

<style scoped>
.star-icon {
  margin-right: 2px;
  color: #FFD141;
  font-size: 1.2rem;
}
</style>
