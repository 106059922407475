<template>
  <div class="review bg-white py-3 px-4 rounded-3 d-flex flex-column">
    <ReviewStars
      :average="review.rating"
      :total="1"
      :with-link="false"
      :stars-only="true"
    />
    <p v-if="showOriginal" v-html="review.review" class="text-start"></p>
    <p v-else v-html="review.translations[localeStore.locale]" class="text-start"></p>
    <div class="user-data text-start d-flex align-items-center gap-2 mt-auto pt-2">
      <Icon type="fas" name="user" customClass="user-icon p-2 fs-5 rounded-circle"/>
      <div class="user-info">
        <div>{{ review.first_name }} {{ review.last_name }}</div>
        <div class="text-muted small">{{ date }}</div>
      </div>
    </div>
    <div
      v-if="review.language !== localeStore.locale"
      class="text-start text-muted small mt-2"
    >
      <img src="@/assets/images/google-translate.png" alt="Google Translate" class="me-1" height="20">
      <span>{{ $t('translated_with_google') }}</span>
      <span
        v-if="!showOriginal"
        class="ms-2 text-decoration-underline text-primary cursor-pointer"
        @click="showOriginal = !showOriginal">{{ $t('show_original') }}</span>
      <span
        v-else
        class="ms-2 text-decoration-underline text-primary cursor-pointer"
        @click="showOriginal = !showOriginal">{{ $t('show_translated') }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ReviewStars from './ReviewStars.vue';
import { computed, ref } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { useDates } from '@/composables/useDates';

const localeStore = useLocaleStore();

const { formatDisplayDate } = useDates();

const props = defineProps<{
  review: any;
}>();

const showOriginal = ref(false);

const date = computed(() => {
  const date = new Date(props.review.review_timestamp * 1000);

  return formatDisplayDate(date);
});
</script>

<style scoped>
.user-icon {
  width: 20px;
  height: 20px;
  background-color: #C7DFF3;
  color: #4F7DBB;
}
</style>