import { useLocaleStore } from '@/stores/localeStore';

export function useDates() {
  const localeStore = useLocaleStore();

  // returns date in format "02/05/2025"
  const formatDate = (date: Date | null) => {
    if (!date) return '';

    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  }

  // returns date in format "February 5, 2025"
  const formatDisplayDate = (date: Date | null) => {
    if (!date) return null;

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return new Intl.DateTimeFormat(localeStore.locale, options).format(date);
  }

  return { formatDate, formatDisplayDate };
}
