<template>
  <section class="my-4">
    <div
      :class="{ 'd-lg-none': items.length > itemsPerView, 'exact-amount': items.length === itemsPerView }"
      class="d-flex overflow-auto scroll-area align-items-stretch py-3"
    >
      <TourCard
        v-for="tour in items"
        :key="tour.productId"
        :title="tour.postTitle"
        :duration="tour.duration"
        :price="tour.price"
        :tourImage="tour.tourImage"
        :permalink="tour.permalink"
        :rating="reviewsShort[tour.productId]?.average"
        :reviews="reviewsShort[tour.productId]?.total"
      />
    </div>

    <ItemCarousel
      v-if="items.length > itemsPerView"
      class="d-none d-lg-flex"
      :items="items"
      :item-key="itemKey"
      :items-per-view="itemsPerView"
      :icon-type="'far'"
    >
      <template #item="{ item }">
        <TourCard
          :title="item.postTitle"
          :duration="item.duration"
          :price="item.price"
          :tourImage="item.tourImage"
          :permalink="item.permalink"
          :rating="reviewsShort[item.productId]?.average"
          :reviews="reviewsShort[item.productId]?.total"
        />
      </template>
    </ItemCarousel>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useEkomiReviews } from '@/composables/useEkomiReviews';
import type { TourData } from '@/types/top-bike-tour';

import ItemCarousel from '@/components/ItemCarousel.vue';
import TourCard from '@/components/TourCard.vue';

const props = defineProps<{
  items: TourData[],
}>();

const itemsPerView = ref(4);
const itemKey      = ref('postTitle');
const itemWidth    = computed(() => (100 / itemsPerView.value));

const productIds = props.items.map((tour) => tour.productId);

const { reviewsShort, loading, error, fetchReviewsShort } = useEkomiReviews();
fetchReviewsShort(productIds);

function updateItemsPerView() {
  itemsPerView.value = window.innerWidth < 1200 ? 3 : 4;
}

onMounted(() => {
  updateItemsPerView();
  window.addEventListener('resize', updateItemsPerView);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateItemsPerView);
});
</script>

<style scoped>
.scroll-area {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scroll-area::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

:deep(.scroll-area .card-container) {
  min-width: 256px;
}

:deep(.exact-amount .card-container) {
  width: v-bind(itemWidth + '%');
}

:deep(.nav-button) {
  position: absolute;
  border: 1px solid #2c508e;
  border-radius: 50%;
  z-index: 10;
  color: #2c508e !important;
  width: 40px;
  height: 40px;

  &.prev {
    left: -8px;
  }

  &.next {
    right: -8px;
  }
}

:deep(.item-slot) {
  min-width: 240px;
}
</style>