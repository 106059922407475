import { ref } from 'vue';

export function useEkomiReviews() {
  const reviewsShort = ref<Record<string, any>>({});
  const reviewsFull = ref<Record<string, any>>({});
  const loading = ref(false);
  const error = ref<string | null>(null);

  /**
   * Fetch reviews from eKomi for given product IDs.
   * @param productIds Array of product IDs
   */
  const fetchReviewsShort = async (productIds: string[]) => {
    if (productIds.length === 0) return;

    const apiUrl = `${import.meta.env.VITE_EKOMI_BASE_URL}/bulk-average/${productIds.join(',')}`;

    loading.value = true;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch reviews short data: ${response.statusText}`);
      }

      const data = await response.json();
      reviewsShort.value = data.data;
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const fetchReviewsFull = async (productId: number, limit: number = 9, offset: number = 0, locale: string = 'en') => {
    if (!productId) return;

    const apiUrl = `${import.meta.env.VITE_EKOMI_BASE_URL}/?product_ids=${productId}&limit=${limit}&offset=${offset}&translate_into=${locale}`;

    loading.value = true;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch reviews full data: ${response.statusText}`);
      }

      const data = await response.json();
      if (offset === 0) {
        reviewsFull.value = data;
      } else {
        reviewsFull.value = {
          ...data,
          data: [...(reviewsFull.value.data || []), ...(data.data || [])]
        };
      }
    } catch (err) {
      error.value = err instanceof Error ? err.message : String(err);
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return { reviewsShort, reviewsFull, loading, error, fetchReviewsShort, fetchReviewsFull };
}
